button{
    background-color: unset;
    border: none;
    vertical-align: middle;
    border: 1px solid transparent;
    font-size: 22px;
}
.carousel_img_move button i{
    padding:3px;
}
.carousel_move{
    line-height: 2;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 1px;
}
.carousel-container {
    display: flex;
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
    position: fixed; /* 고정 위치 */
    top: 0; /* 상단에서 0px */
    left: 0; /* 왼쪽에서 0px */
    width: 100vw; /* 전체 너비 */
    height: 100vh; /* 전체 높이 */
    background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    z-index: 999; /* 다른 요소 위에 나타나게 */
  }
  
  .carousel_box {
    width: 500px; /* 전체 너비 */
    height: 540px; /* 전체 높이 */
    background: white; /* 배경색 */
    padding: 20px; /* 패딩 */
    border-radius: 10px; /* 모서리 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
    padding-bottom: 60px;

  }
  .carousel_img_box{
    width:100%;
    height:100%;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  .carousel_img_move{
    display: flex;
    justify-content: center;
    line-height: 1;
    column-gap: .5rem;
    margin-top: 1rem;
  }