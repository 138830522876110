@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&family=Nanum+Myeongjo:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');



html,body{
  height:100%;
}
body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  font-size:16px;
  color:#333;
}
.basic_font{
  font-family: "Noto Sans KR", sans-serif;
}
.black_font{
  font-family: "Black Han Sans", sans-serif;
  
}
.sand_font{
  font-family: "Quicksand", sans-serif;
}
.gray_color{
  color:#999999;
}
a{
  text-decoration: none;
}
.basic_box{

  padding:2.5em;
}
.intro{
  font-size: 12px;
  text-transform: uppercase;
  color: #999999;
  font-weight: 500;
  letter-spacing: 3px;

}

.logo {
  max-width: 100%; /* 이미지가 부모 요소에 맞게 조정 */
  transition: all 300ms;
  margin-bottom: 10px; /* 마진 조정 */
}

.hello {
  font-weight: 600;
  font-size: 30px;
  margin-top: 15px;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 800;
}

.hello2 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}

.navbox {
  text-align: left;
  align-self: flex-start;
  margin-left: 20px;
}

.nav-tems {
  display: flex;
  text-decoration: none;
  color: #333;
  text-transform: uppercase;
  font-weight: 500;
  align-items: center;
  padding: 10px 0;
}

.nav-tems > span {
  display: inline-block;
  width: 15px;
  height: 2px;
  background: #333;
  transition: all 300ms;
}

.nav-tems:hover,
.nav-tems.action {
  font-weight: 700;
}

.nav-tems:hover > span,
.nav-tems.action > span {
  width: 35px;
}

.nav-tems > svg {
  margin-left: -10px;
  margin-right: 15px;
  font-size: 20px;
}

.snsbox a {
  font-size: 2rem;
  margin: 0 8px; /* 좌우 마진 통합 */
  transition: all 300ms;
}

.snsbox a:first-child {
  color: #181717;
}

.snsbox a:nth-child(2) {
  color: #E1306C;
}

.snsbox a:nth-child(3) {
  color: #FEE500;
}

.snsbox a:last-child {
  color: #4285F4;
}

.snsbox a:hover {
  font-size: 2.2rem;
}

.snsbox a:hover::before {
  font-size: 0.8rem;
  position: absolute;
  display: block;
  width: 60px;
  text-align: right;
  top: -5px;
  right: 5px;
}

.snsbox a.github:hover::before {
  content: "깃허브";
}

.snsbox a.insta:hover::before {
  content: "인스타";
}

.snsbox a.kakao:hover::before {
  content: "카카오톡";
}

.snsbox a.google:hover::before {
  content: "구글";
}

.flex_underline {
  display: flex;
  align-items: center; /* 아이콘과 텍스트 수직 가운데 정렬 */
  margin-top: 10px;
}

.block_underline {
  display: block;
  margin-left: 5px;
  margin-top: 12px;
}
/**** HOME *****/
#home{
  height:1000px;
  font-family: "Nanum Gothic", sans-serif;
}
.home_box{
  display: flex;
  justify-content: center; 
  flex-direction: column; 
  height:100vh;
  font-size:50px;
}
.home_box2{
    display: flex;
    justify-content: center; 
    flex-direction: column; 
    height:100vh;
    font-size:50px;
}
.hbox_m{
  margin:10px 0;

}
.h_name{
  font-size:60px;
}
.about-box, .about-box2, .about-box3 {
  transition: transform 0.5s ease, opacity 0.5s ease; /* 애니메이션 효과 */
  position: absolute; /* 위치를 절대적으로 설정하여 겹치게 함 */
  padding:2.5em;
 
}

.about-box3 {
  transform: translateX(-100%); /* 왼쪽에서 들어오기 위해 이동 */
  opacity: 0; /* 나타남 */
}
.about-box {
  transform: translateX(0); /* 기본 위치 */
  opacity: 1; /* 기본 불투명도 */
}

.about-box.hidden {
  transform: translateX(100%); /* 오른쪽으로 이동 */
  opacity: 0; /* 사라짐 */
}
.about-box2.hidden {
  transform: translateX(100%); /* 오른쪽으로 이동 */
  opacity: 0; /* 사라짐 */
}
.about-box3.hidden{
  transform: translateX(100%); /* 오른쪽으로 이동 */
  opacity: 0; /* 사라짐 */
}
.about-box2 {
  transform: translateX(-100%); /* 왼쪽에서 들어오기 위해 이동 */
  opacity: 0; /* 기본적으로 숨김 */
}

.about-box2.visible {
  transform: translateX(0); /* 기본 위치로 이동 */
  opacity: 1; /* 나타남 */
}
.about-box3.visible{
  transform: translateX(0); /* 기본 위치로 이동 */
  opacity: 1; /* 나타남 */

}

.home_btn{

    padding: 1rem 2rem;
    background-color: #f4623a;
    border: none;
    border-radius: 10rem;
    color: #fff;
    transition: background-color .2s ease-in-out;
    width:250px;
    
}
.home_btn:hover{
  background-color: #E74C3C ;
  transition: background-color .2s ease-in-out;
}
/*** ABOUT ME ****/
.about_text1{
  font-size:20px;
}
.about_text2{
  letter-spacing: 0.2px;
  font-size: 16px;
}
.row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.card{
  width:200px;
  height:200px;
  border-color:#fff;
  box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
}
.about-text{
  text-align: center;
  line-height: 5;
}
.about_images{
  width:40px;
  height:40px;
}
.ft-size{
  font-size: 80px;
}
.text-c{
  text-align: center;
}

.about_banner{
  width:100%;
  height:100px;
  background-color: rgba(210, 210, 210, 0.8); 
  border:1px solid #ddd;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}
/***** profile ****/
.profile_me{
  font-family: "QuickSand", sans-serif;
  font-weight: 600;
}
.profile_box{
  display: flex;
  display: -ms-flexbox;
  flex-flow: row wrap;
  justify-content: space-between;
  -webkit-box-pack: justify;
}
.pr_d_box h5{
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
}
.pr_in_box{
  width: 33.3333%;
}
.pr_d_box{
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-width: 14rem;

}
.pr-ft_size{
  font-size:60px;
}
.ft-size-p{
  font-size:14px;
}
.profile_banner{
  width:100%;
  height:60px;
  border:1px solid #ddd;
  background-color: rgba(221, 221, 221, 0.8); 
  font-family: "Nanum Myeongjo", sans-serif;
}
.profile_text{
  line-height: 3;
  font-weight: bold; 
}

/***** skills *****/
.myskill h5{
  margin-top:10px;
}
.myskill{
  list-style-type: none;
  padding:0;
  width:48%;
  font-size: 14px;
  font-weight: 500;
}
.skill_box{
  display: flex;
  display: -ms-flexbox;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
}
.skill_in_box{
  width: 50%;
}
.skill_d_box{
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-width: 14rem;
}
.myskill h5{
  font-family: "Black Han Sans", sans-serif;
  font-weight: 300;
}

/******** projects *******/

.project_card{
  width:420px;
  height:400px;
  background-color: #fff;
  box-shadow: 0 0 .5rem 0 rgba(68, 68, 68, .4);
  border-radius: 1rem;
  position: relative;
  cursor: pointer;
}

.project_card {
  position: relative;
  width: 420px;
  height: 400px;
  background-color: #fff;
  box-shadow: 0 0 .5rem 0 rgba(68, 68, 68, .4);
  border-radius: 1rem;
  cursor: pointer;
  overflow: hidden; /* 자식 요소가 부모 요소 크기를 넘지 않도록 함 */
}

.project_card img {
  width: 100%;
  height: 100%;
  display: block;
  transition: all 0.3s ease;
}

.text_box {
  position: absolute;
  padding: 1.5rem;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  color: #333;
  

  border-radius: 1rem;
  transform-origin: top; /* 상단에서 닫히는 애니메이션 */
  transition: transform 0.6s ease; /* 애니메이션 설정 */
}

.text_box2 {
  position: absolute;
  padding: 1.5rem;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  color: #333;
  

  border-radius: 1rem;
  transform-origin: top; /* 상단에서 닫히는 애니메이션 */
  transition: transform 0.6s ease; /* 애니메이션 설정 */
}

.project_card:hover img {
  opacity: 0;
}

.project_card:hover .text_box {
  transform: translateY(100%); /* hover 시 text_box가 위에서부터 올라옴 */
}

.project_card:hover .text_box2 {
  transform: translateY(100%); /* hover 시 text_box가 위에서부터 올라옴 */
}

.title1{
display: inline-block; /* 텍스트 크기에 맞게 배경 조절 */
width: auto; /* width 속성을 제거하여 자동 조정 */
padding: .35rem .55rem .15rem .55rem;
margin-bottom: .5rem;
background-color: #f94148;
border-radius: .5rem;
font-weight: 500;
color: #fff;
font-size:20px;
}

.time1{
  padding-bottom: .5rem;
  margin-bottom: .5rem;
  border-bottom: 1px solid #ccc;
  font-size: 0.8rem;
  color: #6c757d;
  font-weight: 400;
}
.project_intro{
  font-size:0.94rem;
  font-weight:700;
}
.project_intro2{
  margin-left:-10px;
  font-size:0.86rem;
  font-weight:400;
}
.project_link{
  font-size: 0.87rem;
  font-weight: 400;
  color:#258ddb;
}
.project_language{
  display: inline-flex;
  white-space: nowrap; /* 한 줄로 유지되도록 함 */
  align-self: flex-start;
  padding: .25rem .75rem;
  margin-top:7px;
  background-color: rgba(249, 197, 29, .2);
  border: 1px solid #f9c51d;
  border-radius: 0.5rem;
  font-size:0.86rem;
}
.link_color{
  color:#258ddb;
  margin-right:5px;
}
.project_btn{
  display: flex;
  gap:0.5rem;
}
.p_btn_1{
  display: flex;
    align-items: center;
    align-self: flex-start;
    column-gap: .25rem;
    padding: .25rem .75rem;
    border: 1px solid #ccc;
    border-radius: .5rem;
    font-weight: 500;
    font-size: .875rem;
    outline: none;
    background-color: #fff;
}
.p_btn_2{
  display: flex;
    align-items: center;
    align-self: flex-start;
    column-gap: .25rem;
    padding: .25rem .75rem;
    border: 1px solid #ccc;
    border-radius: .5rem;
    font-weight: 500;
    font-size: .875rem;
    outline: none;
}

.project_more{
  margin: 0 auto;
  text-align: center;
  line-height: 19.5;
  font-weight: bold;
  font-size:20px;
}
.project_more2{
  margin: 0 auto;
  text-align: center;
  line-height: 17;
  font-weight: bold;
  font-size:20px;
}
.project_more3{
  margin-top: -150px;
  margin-left:10px;
  font-size:11px;
}
.red_star{
  color:#f94148;
}

.project_banner{
  width:100%;
  height:60px;
  border:1px solid #ddd;
  background-color: rgba(221, 221, 221, 0.8);
  font-family: "Nanum Myeongjo", sans-serif;
}
.project_text{
  font-weight: bold; 
  line-height: 3;
}

.pr_span{
  font-size:15px;
  font-weight: 500; 
}

/***** career *****/
.career_box{
  display: flex; 
  border-left:5px solid #333;
  font-weight:700;
  font-size:1.025rem;
}

.career_box2{
  display: flex; 
  border-left:4px solid #f94148;
  font-weight:700;
  font-size:1.025rem;;
  color:#f94148
}
.underline_box{
  border-bottom:1px solid #ccc;
  padding-bottom: 20px;
  width:700px;
  font-size: .875rem;;
}
.underline2_box{
  padding-bottom: 10px;
  width:700px;
  font-size: .875rem;;
}
.career_time{
  font-size: 0.875rem;
  color:#6c757d;
  margin-bottom: 5px;
}
.career_role_box{
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid  #ccc;
  width:700px;
}
.career_role_box2{
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-bottom: 1rem;
  width:700px;
}
.career_role{
  padding: .25rem .75rem;
  background-color: #222;
  border-radius: .5rem;
  font-size: .875rem;
  color: #fff;
}

/***** readme ****/
.readme_container {
  position: fixed; /* 고정 위치 */
  top: 0; /* 상단에서 0px */
  left: 0; /* 왼쪽에서 0px */
  width: 100vw; /* 전체 너비 */
  height: 100vh; /* 전체 높이 */
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
  z-index: 999; /* 다른 요소 위에 나타나게 */
}

.readme_box {
  position: absolute;
  top: 28px; /* 상단에서의 간격 */
  left: calc(50% - 400px); /* 중앙 정렬 */
  width: 800px; /* 전체 너비 */
  max-height: calc(100vh - 56px); /* 전체 높이에서 상단과 하단 여백을 뺀 값 */
  background: white; /* 배경색 */
  border-radius: 10px; /* 모서리 둥글게 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
  overflow-y: auto; /* 수직 스크롤 가능 */
}

.readme_box2 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.readme_banner {
  border-radius: 10px 0 0 0;
  background-color: #222;
  font-weight: 700;
  color: #fff;
  padding: 20px 20px;
  display: flex; /* flexbox로 설정 */
  align-items: center; /* 수직 정렬 */
  justify-content: space-between; /* 양쪽으로 공간 배분 */
}

.btn_close {
  width: 25px; /* 너비 설정 */
  height: 25px; /* 높이 설정 */
  position: relative; /* 위치 설정 */
  top: 10px;
  left: -10px;
  margin-left: 10px; /* 왼쪽 여백 */
  cursor: pointer; /* 커서 포인터 */
  opacity: 0.6;
}

.btn_close::before,
.btn_close::after {
  content: ''; /* 내용 없음 */
  position: absolute; /* 절대 위치 */
  top: 0; /* 상단 정렬 */
  left: 50%; /* 중앙 정렬 */
  width: 100%; /* 너비 100% */
  height: 2px; /* 두께 설정 */
  background-color: gray; /* 회색 배경 */
}

.btn_close::before {
  transform: rotate(45deg); /* 대각선 */
}

.btn_close::after {
  transform: rotate(-45deg); /* 대각선 */
}

.btn_close:hover {
  opacity: 1;
}

.readme_box3 {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  flex: 1 1 auto;
}

.read_title {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #ccc;
  font-weight: 700;
  font-size: 1.5rem;
}

.read_intro {
  margin-bottom: 1.35rem;
  font-weight: 500;
  font-size: .85rem;
}

.read_url {
  margin-bottom: 1.25rem;
  font-weight: 700;
  font-size: 1.25rem;
}

.url_clip {
  font-size: 25px;
  color: #bbb;
  padding-right: 7px;
}

.read_url2 {
  text-decoration: underline;
}

.read_pin {
  font-size: 25px;
  padding-right: 7px;
}

.read_ul {
  padding-left: 1.5rem;
}

.read_my {
  font-size: 15px;
}
.read_skills{
  font-size: 17px;
}
.setup_box{
  height:auto;
  padding: 1rem;
  border-radius: .5rem;
  background-color:  #f5f5f5;
  font-size: .875em;
  font-family: var(--fontStack-monospace, ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace);;
}
/***** pages ******/
section{
  min-height:800px;
}

section:nth-child(3){
  min-height: 500px;
}

/**** navi RWS BTN ****/
.headerbtn{
  font-size:40px;
  visibility: hidden;
  cursor: pointer;
  z-index: 1000;
}
.navi{
  top: -40px; /* 상단 여백 추가 (값을 조정하여 위치 조정 가능) */
  min-height:200px;
  background-color: rgba(220, 220, 220, 0.3);
  border-left:1px solid #ededed;
  border-right:1px solid #ededed;
  padding:10px 1rem 10px;
  position:fixed;
  text-align:left;
  display:flex;
  flex-flow: row wrap;
  flex-direction: column; /* 세로 방향 정렬 */
  justify-content: flex-start;
  align-items: center; /* 중앙 정렬 */
  height:auto;
  width: 80%; /* 유연한 너비 */


 
}

/** media 쿼리 **/
@media (max-width: 575px) {
  .home_box, .home_box2{
    font-size: 1.9rem;
  }
  .readme_box{
    position: fixed;
    left:22px; 
    max-width: 90%;
    margin:0 auto;
  }
  .navi {
    position: fixed;
    top: 0;
    left: -80%; /* 화면 밖으로 숨김 */
    width: 80%;
    height: 100%;
    background-color: rgba(220, 220, 220, 0.3);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left:100px;
    z-index: 999;
   
  }
  
  .col-md-9.shifted{
    transform: translateX(80%);
  }
 

/* 활성화 상태: 화면에 표시 */
.navi.visible {
  transform: translateX(80%); /* 화면 안으로 이동 */
}

  .navi ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .navi li {
    margin: 15px 0;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
  }
  
  .hello {
    font-size: 24px; /* 모바일에 적합한 폰트 크기 */
  }
  .headerbtn{
    visibility: visible;
    position:fixed;
    left:0;
    top:10px;
  }
  .col-md-3 {
    width: 49%; /* col-md-3의 기본 너비 */
  }
  .about_banner{
    width:400px;
    height:150px;
  }

  .pr_in_box{
    width:180px;
    padding:0;
  }
  .pr_d_box>div>div{
    font-size: 0.5rem;
  }
  .profile_banner>h5{
    font-size: 0.7rem;
    line-height: 3;
  }
  .myskill{
    width:100%;
  }
  .pr_span{
   font-size: 0.8rem;
  }
  .project_text{
    font-size: 1rem;
    line-height: 3.5;
  }
 .underline_box,.underline2_box{
  width:100%;
 }
 .career_role_box,.career_role_box2{
  width:100%;
 }

 .h_img_box{
  max-width: 100%;
  display:none;
}
.snsbox{
  max-width: 100%;
  display:none;
}
.navbox{
  max-width:100%;
}
}

@media (min-width: 576px) {
  .navi {
    width: 540px;
  }
}
@media (max-width: 767px){
  .home_box, .home_box2{
    font-size: 1.9rem;
  }
  .readme_box{
    position: fixed;
    left:22px; 
    max-width: 90%;
    margin:0 auto;
  }
  .navi {
    position: fixed;
    top: 0;
    left: -80%; /* 화면 밖으로 숨김 */
    width: 80%;
    height: 100%;
    background-color: rgba(220, 220, 220, 0.3);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left:100px;
    z-index: 999;
   
  }
  
  .col-md-9.shifted{
    transform: translateX(80%);
  }
 

/* 활성화 상태: 화면에 표시 */
.navi.visible {
  transform: translateX(80%); /* 화면 안으로 이동 */
}

  .navi ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .navi li {
    margin: 15px 0;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
  }
  
  .hello {
    font-size: 24px; /* 모바일에 적합한 폰트 크기 */
  }
  .headerbtn{
    visibility: visible;
    position:fixed;
    left:0;
    top:10px;
  }
  .col-md-3 {
    width: 50%; /* col-md-3의 기본 너비 */
    margin: 0 15px 10px 0 ;
    
  }
  .about_banner{
    width:100%;
    height:150px;
  }

  .pr_in_box{
    width:180px;
    padding:0;
  }
  .pr_d_box>div>div{
    font-size: 0.5rem;
  }
  .profile_banner>h5{
    font-size: 0.8rem;
    line-height: 4.5;
  }
 
  .myskill{
    max-width:100%;
    
  }
  .pr_span{
    display: block;
   font-size: 0.8rem;
  }
  .project_text{
    font-size: 0.75rem;
    line-height: 4.5;
  }
  .project_card{
   margin-left:-24px;
  }
  
 .underline_box,.underline2_box{
  width:100%;
 }
 .career_box,.career_box2{
  width: 350px;
 }
 .career_role_box,.career_role_box2{
  width:100%;
 }
 .h_img_box{
  width: 100%;
  display:none;
}
.snsbox{
  width:100%;
  display:none;
}

}
@media (min-width: 768px) {
  .navi {
    width: 720px;
  }
}
@media (max-width: 991px){
  .navi {
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  .navi {
    padding: 100px 1rem 50px;
  }
}
@media (max-width: 1199px){
  .navi {
    visibility: hidden;
  }
}
@media (min-width: 1200px) {
  .navi {
    width: 285px;
  }
}
@media (min-width: 1400px) {
  .navi {
    width: 330px;
  }
} 
